<template>
    <header class="navbar-change-account__container">
       <LogoIcon />
    </header>
</template>

<script>
import LogoIcon from "@core/assets/icons/logo-full.svg"

export default {
    components: {
        LogoIcon
    }
}
</script>

<style lang="scss">
.navbar-change-account__container {
    width: 100%;
    background: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.10);

    svg {
        height: 40px;
        width: 95.4px;
    }
}
</style>
